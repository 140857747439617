import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const PreviewCompatibleImage = ({ imageObject, loading="lazy", className, objectFit="contain" }) => {
  if (!imageObject) {
    console.warn("imageObject missing");
    return null;
  }
  if (!imageObject.image) {
    return null;
  } else if (imageObject.image.childImageSharp) {
    //GatsbyImage from CMS
    return (
      <GatsbyImage
        className={className}
        image={imageObject.image.childImageSharp.gatsbyImageData}
        loading={loading}
        alt={imageObject.alt}
        objectFit={objectFit}
      />
    );
  } else if (imageObject.image.publicURL) {
    //SVG from CMS
    return (
      <img
        className={className}
        src={imageObject.image.publicURL}
        loading={loading}
        alt={imageObject.alt}
        style={{ objectFit: objectFit }}
      />
    );
  } else if (typeof imageObject.image === "string") {
    //Preview IMG for CMS Template
    return (
      <img
        className={className}
        src={imageObject.image}
        alt={imageObject.alt}
        style={{ objectFit: objectFit }}
      />
    );
  } else {
    return null;
  }
};

export default PreviewCompatibleImage;
